// NotFound.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.text}>
        404
      </Typography>
      <Typography variant="h4" className={classes.text}>
        Page Not Found
      </Typography>
      <Button variant="contained" color="primary" href="/">
        Go Home
      </Button>
    </div>
  );
};

export default NotFound;
