import CryptoJS from "crypto-js";
import { Buffer } from "buffer";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || "";

export const decrypt = (encryptedData) => {
  // Konversi data terenkripsi dari Base64 ke Buffer
  const data = Buffer.from(encryptedData, "base64");

  // Panjang IV
  const ivLength = 16; // AES block size in bytes

  // Ekstrak IV dan data terenkripsi
  const iv = CryptoJS.lib.WordArray.create(data.slice(0, ivLength));
  const encryptedText = CryptoJS.lib.WordArray.create(data.slice(ivLength));

  // Konversi kunci menjadi WordArray
  let keyBuffer = Buffer.from(encryptionKey, "hex");
  if (keyBuffer.length < 32) {
    const padding = Buffer.alloc(32 - keyBuffer.length);
    keyBuffer = Buffer.concat([keyBuffer, padding]);
  }
  const key = CryptoJS.lib.WordArray.create(keyBuffer);

  // Dekripsi data
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedText }, key, {
    iv: iv,
  });

  // Ubah hasil dekripsi menjadi string
  return decrypted.toString(CryptoJS.enc.Utf8);
};
