import React, { createContext, useState } from "react";

export const TabsContext = createContext();
const { Provider } = TabsContext;

export const TabsProvider = (props) => {
  const [tabs, setTabs] = useState(0);

  return <Provider value={[tabs, setTabs]}>{props.children}</Provider>;
};
