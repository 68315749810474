import React, { useContext, useState } from "react";
import LoginView from "./View/LoginView";
import axios from "axios";
import { AuthContext } from "../../Context/authContext";
import { AlertError, AlertInfo } from "../../Helpers/SweetAlert";
import { useLocation } from "react-router-dom";
import { set } from "lodash";
import { decrypt } from "../../Helpers/Encryptor";

const Login = (props) => {
  const [accountCheck, setAccountCheck] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [kode, setKode] = useState(process.env.REACT_APP_CODE_COMPANY || "MUSTIKA");
  const [email2, setEmail2] = useState("");
  const [pw, setPw] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const mustika = params.get("mustika");
  // eslint-disable-next-line no-unused-vars
  const [auth, setAuth] = useContext(AuthContext);

  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      props.history.replace("/beranda");
      if (mustika !== null) {
        const decrypted = decrypt(mustika?.replaceAll(' ', '+'));
  
        const [username, code, timestamp] = decrypted.split("||");
        setUsername(username);
        setKode(code);
        props.history.replace("/pengelolaan/pengajuan/add/pembaharuan-data");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HandleLogin = (e) => {
    e.preventDefault();
    let url = process.env.REACT_APP_REST_API_LOCATION_2;
    if (process.env.REACT_APP_MODE === "dev") {
      url = process.env.REACT_APP_REST_API_LOCATION_2_DEV;
    }
    setIsLoading(true);
    try {
      axios
        .post(`${url}/auth/login`, {
          username: username,
          password: pw,
          kode: kode,
        })
        .then((res) => {
          setAuth(res.data.token);
          localStorage.setItem("access_token", res.data.token);
          localStorage.setItem("akses_dapeng", res?.data?.akses_dapeng);
          localStorage.setItem("karyawan_id", res?.data?.karyawan_id);
          localStorage.setItem("logo", res?.data?.logo);
          localStorage.setItem("nama_pt", res?.data?.nama_pt);
          localStorage.setItem("alias", res?.data?.alias);
          localStorage.setItem("role", res?.data?.role);
          localStorage.setItem(
            "is_general_karyawan",
            res?.data?.is_general_karyawan
          );
          localStorage.setItem("menu", JSON.stringify(res?.data?.menu));
          sessionStorage.setItem("is_management", false);

          setIsLoading(false);
          if(mustika !== null){
            props.history.push("/pengelolaan/pengajuan/add/pembaharuan-data");
          }else{
            props.history.push("/beranda");
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.isValidate == true
          ) {
            setAuth(err?.response?.data?.token);
            localStorage.setItem("username", username);
            localStorage.setItem("kode", kode);
            localStorage.setItem("access_token", err?.response?.data?.token);
            localStorage.setItem("karyawan_id", err?.response?.data?.karyawan_id);
            localStorage.setItem("logo", err?.response?.data?.logo);
            localStorage.setItem("nama_pt", err?.response?.data?.nama_pt);
            localStorage.setItem("alias", err?.response?.data?.alias);
            localStorage.setItem("role", err?.response?.data?.role);
            localStorage.setItem("is_general_karyawan", err?.response?.data?.is_general_karyawan)
            setIsLoading(false);
            AlertInfo.fire({
              text: err.response.data.message,
            }).then(() => {
              props.history.push("/validasi-akun/ubah-kata-sandi");
            });
            return;
          }

          AlertInfo.fire({
            text:
              err?.response?.data?.message ?? "Terjadi kesalahan pada server!",
          });
          setIsLoading(false);
        });
    } catch (err) {
      AlertInfo.fire({
        text: err?.response?.data?.message ?? "Terjadi kesalahan pada server!",
      });
      setIsLoading(false);
    }
  };

  const handleChangePw = (e) => setShowPassword(e.target.checked);

  return (
    <LoginView
      onSubmit={HandleLogin}
      showPassword={showPassword}
      accountCheck={accountCheck}
      setAccountCheck={setAccountCheck}
      username={username}
      setUsername={setUsername}
      email2={email2}
      setEmail2={setEmail2}
      pw={pw}
      setPw={setPw}
      kode={kode}
      setKode={setKode}
      handleChangePw={handleChangePw}
      isLoading={isLoading}
    />
  );
};

export default Login;
