import React, { createContext, useState } from "react";

export const PengaturanContext = createContext();
const { Provider } = PengaturanContext;

export const PengaturanProvider = (props) => {
  const [listContext, setListContext] = useState("Ya");

  return (
    <Provider value={[listContext, setListContext]}>
      {props.children}
    </Provider>
  );
};
