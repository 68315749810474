import { Button, Typography } from '@material-ui/core'
import { darken, withStyles } from '@material-ui/core/styles'

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  button: {
    ':disabled': {
      backgroundColor: 'red'
    }
  }
})

const CustomButtonContained = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: (props) => props.color || '#203354',
    textTransform: 'capitalize',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    '&:disabled': {
      background: '#8F99A9 !important',
      color: 'white'
    },
    '&:hover': {
      backgroundColor: (props) => darken(props.color || '#203354', 0.2),
      opacity: '0.8'
    }
  },
  text: {
    fontSize: '12px'
  }
}))(Button)

export const ButtonContained = ({
  text,
  className,
  onClick,
  startIcon,
  type,
  disabled,
  endIcon,
  style,
  textSize = 12,
  color = '#203354',
  component,
  ...props
}) => (
  <CustomButtonContained
    disabled={disabled}
    type={type}
    startIcon={startIcon}
    onClick={onClick}
    className={className}
    endIcon={endIcon}
    style={style}
    color={color}
    component={component}
    {...props}
  >
    <Typography style={{ fontSize: !isNaN(textSize) ? `${textSize}px` : textSize, fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonContained>
)

const CustomButtonContainedDisabled = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#203354',
    textTransform: 'capitalize',
    opacity: '0.5',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    cursor: 'no-drop',
    '&:hover': {
      backgroundColor: '#203354',
      opacity: '0.5'
    }
  },
  text: {
    fontSize: '12px'
  }
}))(Button)

export const ButtonContainedDisabled = ({
  text,
  className,
  onClick,
  startIcon,
  type,
  endIcon
}) => (
  <CustomButtonContainedDisabled
    type={type}
    startIcon={startIcon}
    onClick={onClick}
    className={className}
    endIcon={endIcon}
  >
    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonContainedDisabled>
)

const CustomButtonOutlined = withStyles(() => ({
  root: {
    color: (props) => props.color || '#203354',
    backgroundColor: 'none',
    textTransform: 'capitalize',
    border: (props) => `1px solid ${props.color}` || '1px solid #203354',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    '&:hover': {
      backgroundColor: (props) => props.color || '#203354',
      opacity: '0.8',
      color: 'white'
    }
  }
}))(Button)

export const ButtonOutlined = ({
  text,
  className,
  startIcon,
  onClick,
  type,
  disabled,
  component,
  endIcon,
  descId,
  variant = 'outlined',
  id,
  style,
  color = '#203354',
  textSize = 12,
  size = "medium",
  ...props
}) => (
  <CustomButtonOutlined
    id={id}
    aria-describedby={descId}
    component={component}
    disabled={disabled}
    type={type}
    startIcon={startIcon}
    className={className}
    onClick={onClick}
    endIcon={endIcon}
    variant={variant}
    style={style}
    color={color}
    size={size}
    {...props}
  >
    <Typography style={{ fontSize: !isNaN(textSize) ? `${textSize}px` : textSize, fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonOutlined>
)

const CustomButtonNonActive = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#999999',
    textTransform: 'capitalize',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    '&:hover': {
      backgroundColor: '#999999',
      opacity: '0.8'
    }
  },
  text: {
    fontSize: '12px'
  }
}))(Button)

export const ButtonContainedNonActive = ({
  text,
  className,
  onClick,
  startIcon,
  type,
  disabled,
  endIcon
}) => (
  <CustomButtonNonActive
    disabled={disabled}
    type={type}
    startIcon={startIcon}
    onClick={onClick}
    className={className}
    endIcon={endIcon}
  >
    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonNonActive>
)

const CustomButtonGrey = withStyles(() => ({
  root: {
    color: '#203354',
    backgroundColor: '#E9EBF0',
    textTransform: 'capitalize',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    '&:disabled': {
      background: '#8F99A9 !important',
      color: 'white'
    },
    '&:hover': {
      backgroundColor: '#E9EBF0',
      opacity: '0.8'
    }
  },
  text: {
    fontSize: '12px'
  }
}))(Button)

export const ButtonGrey = ({
  text,
  className,
  onClick,
  startIcon,
  type,
  disabled,
  endIcon,
  ariaDescribedby
}) => (
  <CustomButtonGrey
    aria-describedby={ariaDescribedby}
    disabled={disabled}
    type={type}
    startIcon={startIcon}
    onClick={onClick}
    className={className}
    endIcon={endIcon}
  >
    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonGrey>
)

const CustomButtonRed = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#FF2121',
    textTransform: 'capitalize',
    borderRadius: '12px',
    fontWeight: 'bold !important',
    '&:disabled': {
      background: '#8F99A9 !important',
      color: 'white'
    },
    '&:hover': {
      backgroundColor: '#FF2121',
      opacity: '0.8'
    }
  },
  text: {
    fontSize: '12px'
  }
}))(Button)

export const ButtonRed = ({
  text,
  className,
  onClick,
  startIcon,
  type,
  disabled,
  endIcon
}) => (
  <CustomButtonRed
    disabled={disabled}
    type={type}
    startIcon={startIcon}
    onClick={onClick}
    className={className}
    endIcon={endIcon}
  >
    <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
      {text}
    </Typography>
  </CustomButtonRed>
)