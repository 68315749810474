import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainBox: {
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
    borderRadius: "12px",
    padding: "30px",
    width: "21em",
    [theme.breakpoints.down("xs")]: {
      width: "18em",
      position: "relative",
      left: "1.5em",
    },
  },
  title: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
  },
  width95: {
    width: "95%",
  },
  pt3em: {
    paddingTop: "3em",
  },
  text: {
    fontSize: "12px",
    color: "#203354",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  btn: {
    width: "27em",
    padding: "15px",
    borderRadius: "12px",
  },
  btnDisable: {
    width: "27em",
    padding: "15px",
    borderRadius: "12px",
    opacity: "0.5",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text2: {
    fontSize: "14px",
    position: "relative",
    top: "8px",
    paddingRight: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      position: "relative",
      top: "8px",
    },
  },
  text3: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px !important",
    },
  },
  boxHeader: {
    float: "right",
    paddingRight: "2em",
    paddingTop: "2em",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "1em",
      paddingTop: "1em",
    },
  },
  IconMitra: {
    display: "block",
    margin: "0 auto",
    paddingBottom: "5px",
    position: "relative",
    top: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  buttonProgress: {
    color: "#203354",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  textLogin: {
    fontSize: "30px",
    color: "#203354",
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: 1.1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  mainTextBox: {
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "21em",
  },
  textLoginG: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  boxValidasiBtn: {
    position: "absolute",
    right: "0",
    padding: "20px",
  },
  widthBoxText: {
    width: "8em",
    textAlign: "right",
    paddingRight: "10px",
    color: "#203354",
  },
}));
