import React, { createContext, useState } from 'react';

export const AuthContext = createContext();
const { Provider } = AuthContext;

export const AuthProvider = (props) => {

  const [auth, setAuth] = useState("");

return(

   <Provider value={[auth, setAuth]}>

      {props.children}

   </Provider>

 );

}